export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isEmail(email: string): boolean {
  return EMAIL_REGEX.test(email);
}

export const HEX_REGEX = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

export function isHex(value: string): boolean {
  return HEX_REGEX.test(value);
}

//eslint-disable-next-line
const isFloat = (n: any): boolean => Number(n) === n && n % 1 !== 0;
//eslint-disable-next-line
const isInt = (n: any) => Number(n) === n && n % 1 === 0;
//eslint-disable-next-line
export const isInteger = (num: any) => !isNaN(num) && (isInt(num) || !isFloat(num));

import deleteOutlined from '@iconify/icons-ant-design/delete-outlined';
import editTwotone from '@iconify/icons-ant-design/edit-twotone';
import stockOutlined from '@iconify/icons-ant-design/stock-outlined';
import { Icon } from '@iconify/react';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Header from '../components/header/Header';
import NoData from '../components/NoData';
import DeleteProfile from '../components/profile/DeleteProfile';
import EditProfile from '../components/profile/EditProfile';
import SpinnerFullPage from '../components/spinner/SpinnerFullPage';
import useUser from '../hooks/UseUser';

const items: string[] = ['Stats', 'Edit', 'Delete'];

export default function Profile(): JSX.Element {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const { user } = useUser();
  const location = useLocation();

  /**
   * Set the new tab active
   *
   * @param index
   */
  const onSelectTab = (index: number) => {
    setSelectedTab(index);
    window.location.hash = `${items[index]}`;
  };

  /**
   * When the hash is changing, navigate the tab to the given tab
   */
  useEffect(() => {
    const index = items.indexOf(location.hash.replace('#', ''));
    if (index > -1) {
      setSelectedTab(index);
    }
  }, [location, selectedTab]);

  if (user) {
    return (
      <>
        <Header title='Profile' />

        <div className='flex items-center justify-center'>
          <Tabs className='w-full' selectedIndex={selectedTab} onSelect={index => onSelectTab(index)}>
            <TabList className='flex flex-wrap justify-center text-sm font-medium text-center text-gray-400 border-b border-gray-700 mb-5'>
              {items.map((title, index) => (
                <Tab
                  key={index}
                  className={classNames('flex items-center p-4 pt-1 rounded-t-lg border-b-2 mr-2 outline-none cursor-pointer', {
                    'text-gray-200 border-blue-500': selectedTab === index,
                    'border-transparent hover:border-gray-300 hover:text-gray-300 ': selectedTab !== index,
                  })}
                >
                  <span className='hidden xs:inline-block'>
                    {title === 'Stats' && <Icon width={15} className='text-yellow-600 mr-1' icon={stockOutlined} />}
                    {title === 'Edit' && <Icon width={15} className='text-yellow-600 mr-1' icon={editTwotone} />}
                    {title === 'Delete' && <Icon width={15} className='text-yellow-600 mr-1' icon={deleteOutlined} />}
                  </span>
                  {title}
                </Tab>
              ))}
            </TabList>

            <TabPanel>
              {user.devices.length === 0 && <NoData />}
              {user.devices.length > 0 && <>TODO toon devices hier.....</>}
            </TabPanel>

            <TabPanel>
              <div className='mx-auto mb-5 lg:w-2/4 sm:w-3/4 rounded-sm px-5 pb-20'>
                <EditProfile />
              </div>
            </TabPanel>

            <TabPanel>
              <div className='mx-auto mb-5 lg:w-2/4 sm:w-3/4 rounded-sm px-5 pb-20'>
                <DeleteProfile
                  onRequested={() => {
                    setSelectedTab(0);
                    window.location.hash = `${items[0]}`;
                  }}
                />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </>
    );
  }

  return <SpinnerFullPage />;
}

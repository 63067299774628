import classNames from 'classnames';
import React, { ForwardedRef, useEffect, useState } from 'react';
import { inputClassesDark, inputClassesLight } from '../../helpers/CssClasses';

interface Option {
  label: string;
  value: string;
}

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: Option[] | string[];
  mode?: 'dark' | 'light';
  className?: string;
}

/**
 * Default select element with the correct styles
 *
 * We can use this <Select/> component inside the Forms-Hooks as it is 100% compatible with the hooks
 */
const Select = React.forwardRef(function Select(
  { options, className, mode = 'light', ...rest }: Props,
  ref: ForwardedRef<HTMLSelectElement>,
): JSX.Element {
  const [selectOptions, setSelectOptions] = useState<Option[]>([]);

  useEffect(() => {
    // check if the array has an Option[] interface
    // If not, it has a string[] interface, and we should convert it to an option[] interface
    if (options.length > 0 && typeof options[0] === 'string') {
      setSelectOptions(options.map(value => ({ label: value, value } as Option)));
    } else {
      setSelectOptions(options as Option[]);
    }
  }, [options]);

  return (
    <div className={classNames('relative inline-block w-full', className)}>
      <select
        className={classNames('w-full text-sm appearance-none focus:border-0', mode === 'light' ? inputClassesLight : inputClassesDark)}
        {...rest}
        ref={ref}
      >
        {selectOptions.map(option => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className='absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none'>
        <svg className='w-4 h-4 fill-current' viewBox='0 0 20 20'>
          <path
            d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
            clipRule='evenodd'
            fillRule='evenodd'
          />
        </svg>
      </div>
    </div>
  );
});

export default Select;

import classNames from 'classnames';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { inputWarningClass, textareaClassesDark } from '../../helpers/CssClasses';
import { sendAccountDeletionRequest } from '../../services/User';
import { Alert } from '../alert/Alert';
import Button from '../Button';
import FormField from '../formElements/FormField';
import FormFieldError from '../formElements/FormFieldError';

interface Props {
  onRequested: () => void;
}

export interface UserProfileFormModel {
  reason: string;
}

export default function DeleteProfile({ onRequested }: Props): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserProfileFormModel>();

  /**
   * Function that be called when submitting the form
   */
  const onSubmit = async ({ reason }: UserProfileFormModel) => {
    setSubmitting(true);

    try {
      // Send deletion request
      await sendAccountDeletionRequest(reason ?? '');

      setErrorMessage('');
      toast.success('Profile deletion reqested');
    } catch (error) {
      setErrorMessage(`Something went wrong: ${error}`);
    } finally {
      setSubmitting(false);
      onRequested();
    }
  };

  return (
    <>
      {errorMessage && <Alert type='warning' title='Error' description={errorMessage} />}

      <h2 className='mb-5 text-white text-lg'>Account deletion</h2>
      <p className='text-white text-sm mb-4'>
        To request an account deletion, please click on &quot;Request account deletion&quot;. Optional you can write down a reason for your
        request.
      </p>
      <p className='text-gray-300 text-xs '>We will process your request within 72 hours</p>

      <form className='mt-10' onSubmit={handleSubmit(onSubmit)}>
        <FormField>
          <textarea
            placeholder='Optional reason for account deletion'
            className={classNames(textareaClassesDark, { [inputWarningClass]: errors.reason })}
            rows={15}
            {...register('reason')}
          />
          {errors.reason && <FormFieldError>{errors.reason.message}</FormFieldError>}
        </FormField>

        <div className='mt-5'>
          <Button type='primary' htmlType='submit' loading={submitting}>
            Request account deletion
          </Button>
        </div>
      </form>
    </>
  );
}

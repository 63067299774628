import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import timezones from 'timezones-list';
import { inputClassesLight, inputWarningClass } from '../../helpers/CssClasses';
import { EMAIL_REGEX } from '../../helpers/Validators';
import { signup, signupComplete } from '../../services/User';
import { Alert } from '../alert/Alert';
import Button from '../Button';
import FormField from '../formElements/FormField';
import FormFieldDescription from '../formElements/FormFieldDescription';
import FormFieldError from '../formElements/FormFieldError';
import FormLabel from '../formElements/FormLabel';
import Select from '../formElements/Select';
import Spinner from '../spinner/Spinner';

interface FormModel {
  name: string;
  email: string;
  password: string;
  timezone: string;
}

export default function RegisterForm(): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [completeRegistration, setCompleteRegistration] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormModel>({ defaultValues: { timezone: 'Europe/Amsterdam' } });

  const timezonesList = useMemo(
    () =>
      timezones
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }

          // names must be equal
          return 0;
        })
        .map(val => ({
          label: val.tzCode,
          value: val.tzCode,
        })),
    [],
  );

  /**
   * Function that be called when submitting the form
   */
  const onSubmit = async ({ name, email, password, timezone }: FormModel) => {
    try {
      setSubmitting(true);
      await signup(name, email, password, timezone);

      setErrorMessage('');
      navigate('/#signup_success');
    } catch (error) {
      setErrorMessage(error as string);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Complete registration
   */
  useEffect(() => {
    if (location.hash) {
      setCompleteRegistration(true);
      const token = location.hash.replace('#token=', '');
      signupComplete(token)
        .then(() => navigate('/#signup_complete'))
        .catch(e => {
          toast.error(e, { autoClose: 5000, onClose: () => navigate('/') });
        });
    }
  }, [location, navigate]);

  /**
   * Show a spinner while we complete the registration
   */
  if (completeRegistration) {
    return (
      <div className='flex items-center'>
        <Spinner inverseColor={true} className='mr-2' /> <span className='text-sm'>complete registration</span>
      </div>
    );
  }

  return (
    <>
      {errorMessage && <Alert type='warning' title='Error' description={errorMessage} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField>
          <FormLabel htmlFor='name'>Name</FormLabel>
          <input
            className={classNames(inputClassesLight, { [inputWarningClass]: errors.name })}
            placeholder='John Doe'
            type='name'
            {...register('name', { required: 'this field is required' })}
          />
          {errors.name && <FormFieldError>{errors.name.message}</FormFieldError>}
        </FormField>

        <FormField>
          <FormLabel htmlFor='email'>Email</FormLabel>
          <input
            className={classNames(inputClassesLight, { [inputWarningClass]: errors.email })}
            placeholder='john@doe.com'
            type='email'
            {...register('email', {
              required: 'this field is required',
              pattern: {
                value: EMAIL_REGEX,
                message: 'Incorrect e-mail address',
              },
            })}
          />
          {errors.email && <FormFieldError>{errors.email.message}</FormFieldError>}
        </FormField>

        <FormField>
          <FormLabel htmlFor='timezone'>Timezone</FormLabel>
          <FormFieldDescription>
            The timezone is needed to set the correct dates across the application. It also set the correct time for sending the push
            notifications
          </FormFieldDescription>
          <Select
            id='timezone'
            options={timezonesList}
            className={classNames({ [inputWarningClass]: errors.name })}
            {...register('timezone', { required: 'this field is required', value: 'Europe/Amsterdam' })}
          />
          {errors.timezone && <FormFieldError>{errors.timezone.message}</FormFieldError>}
        </FormField>

        <FormField>
          <FormLabel htmlFor='password'>Password</FormLabel>
          <input
            className={classNames(inputClassesLight, { [inputWarningClass]: errors.password })}
            type='password'
            {...register('password', { required: 'this field is required' })}
          />
          {errors.password && <FormFieldError>{errors.password.message}</FormFieldError>}
        </FormField>

        <div className='flex justify-center my-3'>
          <Button htmlType='submit' type='primary' className='w-3/4' loading={submitting}>
            Register
          </Button>
        </div>

        <div className='flex justify-center items-center'>
          <Link to='/' className='text-sm mr-2'>
            Already have an account?
          </Link>
        </div>
      </form>
    </>
  );
}

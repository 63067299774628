import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUserObject } from '../../context/UserContext';
import { login } from '../../services/User';
import { Alert } from '../alert/Alert';
import Button from '../Button';
import Password from '../formElements/Password';

interface FormModel {
  email: string;
  password: string;
}

export default function LoginForm(): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { setUser } = useUserObject();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormModel>();

  // console.log(watch('email')); // watch input value by passing the name of it

  /**
   * Function that be called when submitting the form
   */
  const onSubmit = async (submittedData: FormModel) => {
    try {
      setSubmitting(true);
      const user = await login(submittedData.email, submittedData.password);

      setErrorMessage('');
      setUser(user);
      reset();
    } catch (e) {
      setErrorMessage('Either your username and/or password is wrong, or this user does not exists.');
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (location.hash === '#signup_complete') {
      toast.success('Registration complete, please login');
    } else if (location.hash === '#signup_success') {
      toast.success('Thank you for your registration. Please login.');
    }
  }, [location]);

  return (
    <>
      {errorMessage && <Alert type='warning' title='Error' description={errorMessage} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-4'>
          <label className='block text-gray-700 text-sm mb-2' htmlFor='email'>
            Email
          </label>
          <input
            autoFocus={true}
            className='appearance-none border rounded w-full py-2 px-3 text-gray-700 text-sm focus:outline-none focus:shadow-outline'
            placeholder='john@doe.com'
            type='email'
            {...register('email', { required: true })}
          />
          {errors.email && <p className='text-red-500 text-xs italic pt-1'>This fields is required.</p>}
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700 text-sm mb-2' htmlFor='password'>
            Password
          </label>
          <Password newPassword={false} {...register('password', { required: true })} />
          {/* <input
            className='appearance-none border rounded w-full py-2 px-3 text-sm text-gray-700 focus:outline-none focus:shadow-outline'
            type='password'
            {...register('password', { required: true })}
          /> */}
          {errors.password && <p className='text-red-500 text-xs italic pt-1'>This fields is required.</p>}
        </div>

        <div className='flex justify-center my-3'>
          <Button loading={submitting} htmlType='submit' type='primary' fullWidth={true}>
            Login
          </Button>
        </div>

        <div className='flex justify-center items-center'>
          <Link to={'/signup'} className='text-sm mr-2'>
            Register an account
          </Link>
          {/*<span className='text-sm'>-</span>*/}
          {/*<Button type='link' className='text-sm'>*/}
          {/*  Forgot password*/}
          {/*</Button>*/}
        </div>
      </form>
    </>
  );
}

import { CustomHttpError, httpClient } from '../helpers/httpClient';

export interface Device {
  id: number;
  name: string;
  token: string;
  lastActivity: string | null;
  user: {
    id: number;
    name: string;
    email: string;
  };
}

export async function generateDevice(): Promise<Device> {
  try {
    const { data } = await httpClient.post<Device>('/device', {
      name: 'new Device',
    });

    return Promise.resolve(data);
  } catch (e) {
    if (e instanceof CustomHttpError || e instanceof Error) {
      return Promise.reject(e.message);
    }

    return Promise.reject('unknown error');
  }
}

export async function listDevices(signal?: AbortSignal): Promise<Device[]> {
  try {
    const { data } = await httpClient.get<Device[]>('/device', {
      signal,
    });

    return Promise.resolve(data);
  } catch (e) {
    if (e instanceof CustomHttpError || e instanceof Error) {
      return Promise.reject(e.message);
    }

    return Promise.reject('unknown error');
  }
}

export async function getDevice(deviceId: number, signal?: AbortSignal): Promise<Device> {
  try {
    const { data } = await httpClient.get<Device>(`/device/${deviceId}`, { signal });
    return Promise.resolve(data);
  } catch (e) {
    if (e instanceof CustomHttpError || e instanceof Error) {
      return Promise.reject(e.message);
    }

    return Promise.reject('unknown error');
  }
}

export async function updateDevice(device: Device): Promise<void> {
  try {
    const { data } = await httpClient.patch<void>(`/device/${device.id}`, {
      name: device.name,
      token: device.token,
    } as Partial<Device>);

    return Promise.resolve(data);
  } catch (e) {
    if (e instanceof CustomHttpError || e instanceof Error) {
      return Promise.reject(e.message);
    }

    return Promise.reject('unknown error');
  }
}

export async function removeDevice(deviceId: number): Promise<void> {
  try {
    await httpClient.delete<Device>(`/device/${deviceId}`);

    return Promise.resolve();
  } catch (e) {
    if (e instanceof CustomHttpError || e instanceof Error) {
      return Promise.reject(e.message);
    }

    return Promise.reject('unknown error');
  }
}

export async function resetDeviceToken(deviceId: number): Promise<void> {
  try {
    await httpClient.post<Device>(`/device/reset_token/${deviceId}`);

    return Promise.resolve();
  } catch (e) {
    if (e instanceof CustomHttpError || e instanceof Error) {
      return Promise.reject(e.message);
    }

    return Promise.reject('unknown error');
  }
}

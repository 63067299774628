import './wdyr'; // Need to be the first import!!
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import App from './App';
import { DialogProvider } from './context/DialogContext';
import { UserProvider } from './context/UserContext';

const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <DialogProvider dialogsIds={[]}>
          <App />
          <ToastContainer />
        </DialogProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

import { CustomHttpError, httpClient } from '../helpers/httpClient';

export interface Coordinate {
  id: number;
  lat: number;
  lng: number;
  isLora: boolean;
  radius: number;
  date: string;
  isStopped: boolean;
}

export async function getCoordinates(appId: number, date: string, signal?: AbortSignal): Promise<Coordinate[]> {
  try {
    const { data } = await httpClient.get<Coordinate[]>(`/gps/${appId}?date=${date}`, {
      signal,
    });

    return Promise.resolve(data);
  } catch (e) {
    if (e instanceof CustomHttpError || e instanceof Error) {
      return Promise.reject(e.message);
    }

    return Promise.reject('unknown error');
  }
}

export async function coordinateAvailPerMonth(appId: number, year: number, month: number): Promise<string[]> {
  try {
    const { data } = await httpClient.get<string[]>(`/gps/${appId}/daysAvailableForMonth?year=${year}&month=${month}`);

    return Promise.resolve(data);
  } catch (e) {
    if (e instanceof CustomHttpError || e instanceof Error) {
      return Promise.reject(e.message);
    }

    return Promise.reject('unknown error');
  }
}

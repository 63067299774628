import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

interface Props {
  dialogsIds: string[];
  children: React.ReactNode;
}

type DialogContextType = {
  dialogsIds: string[];
  setDialogsIds: Dispatch<SetStateAction<string[]>>;
  lastDialogId: string | undefined;
};

const DialogContext = createContext<DialogContextType>({
  dialogsIds: [],
  setDialogsIds: () => console.warn('No Dialog provider'),
  lastDialogId: '',
});

export function useDialogContext(): DialogContextType {
  return useContext(DialogContext);
}

export function DialogProvider({ children }: Props): JSX.Element {
  const [dialogsIds, setDialogsIds] = useState<string[]>([]);
  const lastDialogId = dialogsIds.at(dialogsIds.length - 1);

  return <DialogContext.Provider value={{ dialogsIds, setDialogsIds, lastDialogId }}>{children}</DialogContext.Provider>;
}

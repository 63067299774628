import classNames from 'classnames';
import React, { ForwardedRef } from 'react';

interface Props {
  value: string;
  elementClassName: string | undefined;
  bordered: boolean | undefined;
}

const CopyAsSpan = React.forwardRef(function CopyAsSpan(
  { elementClassName, value, bordered }: Props,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  return (
    <span
      ref={ref}
      className={classNames('inline-block cursor-pointer text-sm outline-none bg-transparent truncate', elementClassName, {
        'font-mono px-1': bordered,
      })}
    >
      {value}
    </span>
  );
});

export default CopyAsSpan;

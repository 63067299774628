import 'leaflet/dist/leaflet.css';
import 'react-datepicker/dist/react-datepicker.css';
import './Map.css';
import ColorHash from 'color-hash';
import L from 'leaflet';
import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { Coordinate } from '../../services/Coordinates';
import Chart from './Timeline';

interface Props {
  coordinates: Coordinate[];
  asRoute: boolean;
}

/* This code is needed to properly load the images in the Leaflet CSS */
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('../../assets/markers/marker-icon-2x-blue.png'),
  iconUrl: require('../../assets/markers/marker-icon-blue.png'),
  shadowUrl: require('../../assets/markers/marker-shadow.png'),
});

const greyIcon = new L.Icon({
  iconUrl: require('../../assets/markers/marker-icon-2x-grey.png'),
  shadowUrl: require('../../assets/markers/marker-shadow.png'),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const colorHash = new ColorHash();
const DEFAULT_LINE_WEIGHT = 10;

export default function Map({ coordinates, asRoute }: Props): JSX.Element {
  const [map, setMap] = useState<L.Map>();
  const [markerGroup, setMarkerGroup] = useState<L.FeatureGroup>();
  const [markerGroupCached, setMarkerGroupCached] = useState<L.FeatureGroup>();
  const [circleGroup, setCircleGroup] = useState<L.FeatureGroup>();
  const [circleGroupCached, setCircleGroupCached] = useState<L.FeatureGroup>();
  const [ploylineGroup, setPloylineGroup] = useState<L.LayerGroup>();
  const [ploylineGroupCached, setPloylineGroupCached] = useState<L.LayerGroup>();
  const [ploylineStartEndMarkerGroup, setPloylineStartEndMarkerGroup] = useState<L.LayerGroup>();
  const [ploylineStartEndMarkerGroupCached, setPloylineStartEndMarkerGroupCached] = useState<L.LayerGroup>();

  const addMarker = (coordinate: Coordinate): L.Marker => {
    const marker = L.marker({ lat: coordinate.lat, lng: coordinate.lng }, coordinate.isLora ? { icon: greyIcon } : {});
    const dateHtml = `<b>Time:</b> ${DateTime.fromISO(coordinate.date).toFormat('HH:mm:ss')}`;
    const coordinateHtml = `<br/><b>Coordinates:</b> ${coordinate.lat}.${coordinate.lng}`;
    const loraHtml = coordinate.isLora ? `<br/><b>Lora radius:</b> ${coordinate.radius} meters` : '';

    marker.bindPopup(dateHtml + coordinateHtml + loraHtml);

    return marker;
  };

  /**
   * Load the map
   */
  useEffect(() => {
    setMap(prevState => {
      if (!prevState) {
        return L.map('map').setView([-43.4175044, 172.185657], 8);
      }

      return prevState;
    });
  }, []);

  /**
   * Set the layer groups for the map and add an osm tile
   */
  useEffect(() => {
    if (!map) return;
    setPloylineGroup(L.layerGroup([]).addTo(map));
    setMarkerGroup(new L.FeatureGroup([]).addTo(map));
    setCircleGroup(new L.FeatureGroup([]).addTo(map));
    setPloylineStartEndMarkerGroup(new L.FeatureGroup([]).addTo(map));
    setMarkerGroupCached(new L.FeatureGroup([]));
    setCircleGroupCached(new L.FeatureGroup([]));
    setPloylineGroupCached(new L.FeatureGroup([]));
    setPloylineStartEndMarkerGroupCached(new L.FeatureGroup([]));

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '© OpenStreetMap',
    }).addTo(map);
  }, [map]);

  /**
   * When we receive new coordinates, clear the layers for the groups
   */
  useEffect(() => {
    if (
      !ploylineGroup ||
      !markerGroup ||
      !markerGroupCached ||
      !circleGroup ||
      !circleGroupCached ||
      !ploylineGroupCached ||
      !ploylineStartEndMarkerGroup ||
      !setPloylineStartEndMarkerGroupCached
    ) {
      return;
    }

    ploylineGroup.clearLayers();
    markerGroup.clearLayers();
    markerGroupCached.clearLayers();
    ploylineStartEndMarkerGroup.clearLayers();
    circleGroup.clearLayers();
    circleGroupCached.clearLayers();
    ploylineGroupCached.clearLayers();
    ploylineStartEndMarkerGroup.clearLayers();
  }, [
    coordinates,
    ploylineGroup,
    markerGroup,
    markerGroupCached,
    circleGroup,
    circleGroupCached,
    ploylineGroupCached,
    ploylineStartEndMarkerGroup,
  ]);

  /**
   * Create markers
   */
  useEffect(() => {
    if (asRoute) return;
    if (!map || !markerGroup || !markerGroupCached || !circleGroup || !circleGroupCached || coordinates.length === 0) return;

    markerGroup.clearLayers();
    markerGroupCached.clearLayers();

    for (const coordinate of coordinates) {
      const marker = addMarker(coordinate);
      markerGroup.addLayer(marker);
      markerGroupCached.addLayer(marker);

      if (coordinate.isLora) {
        const circle = L.circle({ lat: coordinate.lat, lng: coordinate.lng }, { radius: coordinate.radius, fillColor: '#000' });
        circleGroup.addLayer(circle);
        circleGroupCached.addLayer(circle);
      }
    }

    // add to the map
    markerGroup.addTo(map);

    // zoom the map to the polyline
    map.fitBounds(markerGroup.getBounds());
  }, [map, coordinates, markerGroup, markerGroupCached, circleGroup, circleGroupCached, asRoute]);

  /**
   * Create the polyline for the given coordinates
   */
  useEffect(() => {
    if (!asRoute) return;
    if (
      coordinates.length === 0 ||
      !map ||
      !ploylineGroup ||
      !ploylineGroupCached ||
      !markerGroup ||
      !markerGroupCached ||
      !ploylineStartEndMarkerGroup ||
      !ploylineStartEndMarkerGroupCached
    ) {
      return;
    }

    markerGroup.clearLayers();
    ploylineGroup.clearLayers();

    // we sourch for the start and end points in the first reduce
    // the second has the result of an array of start and endpoints
    // based on that we get the coordinates and slice them out of the
    // coordinates array
    const polylines: Array<Coordinate[]> = coordinates
      .reduce<number[]>((prev, current, i) => {
        // push the first or last
        if (i === 0 || i === coordinates.length - 1) {
          prev.push(i);
          return prev;
        }

        const previousCoordinate = coordinates[i - 1];
        if (previousCoordinate) {
          const currentDate = DateTime.fromISO(current.date);
          const previousDate = DateTime.fromISO(previousCoordinate.date);

          // if the diff is more than 2 minutes, then we push it to the array
          if (currentDate.diff(previousDate, 'second').seconds > 60 * 2) {
            prev.push(i - 1);
            prev.push(i);
          }
        }

        return prev;
      }, [])
      .reduce<Array<Coordinate[]>>((prev, _, i, numberArray) => {
        if (i % 2 === 1) {
          const start = numberArray[i - 1];
          const end = numberArray[i];
          prev.push(coordinates.slice(start, end));
        }
        return prev;
      }, []);

    // per route we add a line
    // there is a weird leaflet bug that shows a line that is incorrect
    // it is random showed
    const polyLeafletObjects: L.Polyline[] = [];

    polylines.forEach(polyline => {
      if (polyline.length === 0) return;
      const poly = L.polyline(
        polyline.map(coordinate => [coordinate.lat, coordinate.lng]),
        {
          className: nanoid(),
          weight: DEFAULT_LINE_WEIGHT,
          color: colorHash.hex(`${polyline[0].lat},${polyline[0].lng}`),
        },
      );

      // add the start and end markers
      const startMarker = addMarker(polyline[0]);
      const endMarker = addMarker(polyline[polyline.length - 1]);
      ploylineStartEndMarkerGroup.addLayer(startMarker);
      ploylineStartEndMarkerGroup.addLayer(endMarker);
      ploylineStartEndMarkerGroupCached.addLayer(startMarker);
      ploylineStartEndMarkerGroupCached.addLayer(endMarker);

      ploylineGroup.addLayer(poly);
      ploylineGroupCached.addLayer(poly);

      //save the poly leaflet objects
      polyLeafletObjects.push(poly);
    });

    // set the onClick handler
    polyLeafletObjects.forEach(poly => {
      poly.on('click', () => {
        // first reset the weight for all (except the given) polylines
        polyLeafletObjects
          .filter(pol => pol.options.className !== poly.options.className)
          .forEach(pol => pol.setStyle({ weight: DEFAULT_LINE_WEIGHT }));

        // set the style
        poly.setStyle({
          weight: poly.options.weight === DEFAULT_LINE_WEIGHT ? 15 : DEFAULT_LINE_WEIGHT,
        });

        // move the line to front (e.g zindex change)
        poly.bringToFront();
      });
    });

    // collect all polylines so we can fit the bounds
    const allPolylines = L.polyline(coordinates.map(coordinate => [coordinate.lat, coordinate.lng]));

    // zoom the map to the polyline
    // this will also update the map
    map.fitBounds(allPolylines.getBounds());
  }, [
    ploylineGroup,
    map,
    coordinates,
    asRoute,
    markerGroup,
    ploylineGroupCached,
    markerGroupCached,
    ploylineStartEndMarkerGroup,
    ploylineStartEndMarkerGroupCached,
  ]);

  if (!coordinates) {
    return <></>;
  }

  return (
    <>
      <div className='h-[calc(100vh-325px)] w-screen' id='map' />
      <Chart
        coordinates={coordinates}
        onHoverEnter={coordinate => {
          if (!map || !markerGroup || !circleGroup) return;

          markerGroup.clearLayers();
          markerGroup.addLayer(L.marker([coordinate.lat, coordinate.lng], coordinate.isLora ? { icon: greyIcon } : {}));

          circleGroup.clearLayers();

          if (coordinate.isLora) {
            const circle = L.circle({ lat: coordinate.lat, lng: coordinate.lng }, { radius: coordinate.radius, fillColor: '#000' });
            circleGroup.addLayer(circle);
          }
        }}
        onHoverLeave={() => {
          if (!map || !markerGroup || !markerGroupCached || !circleGroup || !circleGroupCached || !ploylineGroupCached) return;

          if (!asRoute) {
            markerGroup.clearLayers();
            markerGroupCached.eachLayer(layer => markerGroup.addLayer(layer));
          }

          circleGroup.clearLayers();
          circleGroupCached.eachLayer(layer => circleGroup.addLayer(layer));
        }}
      />
    </>
  );
}

import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Modal, { ModalWidth } from '../Modal';

export default function AuthWrapper(): JSX.Element {
  const location = useLocation();

  const title = location.pathname === '/' ? 'Login' : 'Register';

  return (
    <Modal
      remainSizeOnSmallerScreens={true}
      enableOverlay={false}
      width={ModalWidth.xs}
      closable={false}
      title={title}
      visible={true}
      okText={'Login'}
      showCloseIcon={false}
      showButtons={false}
    >
      <Outlet />
    </Modal>
  );
}

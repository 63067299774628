import classNames from 'classnames';
import React from 'react';

interface Props {
  children?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  alwaysFlex?: boolean; // this will not put the right column to the bottom, used for the groups
}

export default function ListItemWrapper({ onClick, children, className, alwaysFlex }: Props): JSX.Element {
  return (
    <li
      className={classNames('relative px-3 py-3 border-gray-300 border-b last:border-0 justify-between', className, {
        'cursor-pointer': onClick,
        'sm:flex': !alwaysFlex,
        flex: alwaysFlex,
      })}
      onClick={onClick}
    >
      {children}
    </li>
  );
}

import stockOutlined from '@iconify/icons-ant-design/stock-outlined';
import { Icon } from '@iconify/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/header/Header';
import List from '../components/list/List';
import ListItem from '../components/list/ListItem';
import SpinnerFullPage from '../components/spinner/SpinnerFullPage';
import useUser from '../hooks/UseUser';

export default function Home(): JSX.Element {
  const { user } = useUser();
  const navigate = useNavigate();

  if (user) {
    return (
      <>
        <Header
          title={
            <>
              <Icon className='text-yellow-600' icon={stockOutlined} />
              Your Devices
            </>
          }
        />

        <List loading={false} ulClassName='overflow-auto max-h-60'>
          {user.devices.map(device => (
            <ListItem key={device.id} alwaysFlex={true} className='bg-gray-200' onClick={() => navigate(`/map/${device.id}`)}>
              {device.name}
            </ListItem>
          ))}
        </List>
      </>
    );
  }

  return <SpinnerFullPage />;
}

import classNames from 'classnames';
import React from 'react';
import ListItemLoading from './ListItemLoading';

interface Props {
  renderBottomMargin?: boolean;
  loading: boolean;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  className?: string;
  ulClassName?: string;
  fullWidth?: boolean;
  inverseColor?: boolean;
}

export default function List({
  loading,
  children,
  footer,
  header,
  className,
  ulClassName,
  fullWidth,
  inverseColor,
  renderBottomMargin,
}: Props): JSX.Element {
  return (
    <div
      className={classNames(className, {
        'mx-auto mb-4 lg:w-3/4 px-2 sm:px-3 md:px-4': !fullWidth,
        'w-full mb-5': fullWidth,
        'mb-24': renderBottomMargin,
      })}
    >
      {header}

      <ul
        className={classNames('rounded-md', ulClassName, {
          'bg-gray-50': !inverseColor,
          'bg-gray border-gray-200 border': inverseColor,
        })}
      >
        {loading && <ListItemLoading />}

        {children}
      </ul>

      {footer}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useUser from '../../hooks/UseUser';
import { Device, updateDevice } from '../../services/Device';
import FormField from '../formElements/FormField';
import FormFieldDescription from '../formElements/FormFieldDescription';
import FormFieldError from '../formElements/FormFieldError';
import FormLabel from '../formElements/FormLabel';
import Modal from '../Modal';

interface FormModel {
  name: string;
  token: string;
}

interface Props {
  device: Device | undefined;
  showModal: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export default function EditDeviceModal({ device, showModal, onClose, onSuccess }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const { loadUser } = useUser();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormModel>();

  /**
   * Function that be called when submitting the form
   */
  const onSubmit = async ({ name, token }: FormModel) => {
    if (device) {
      setSubmitting(true);
      const updateDeviceRequest: Device = {
        ...device,
        name,
        token,
      };

      try {
        await updateDevice(updateDeviceRequest);
        onSuccess();
        onClose();
        loadUser(); // this will update the groups into the user object

        toast.success('Successfully updated Device');
      } catch (e) {
        toast.error('Failed to update Device');
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    setIsVisible(!!device && showModal);

    if (device) {
      setValue('name', device.name);
      setValue('token', device.token);
    }
  }, [showModal, device, setValue]);

  return (
    <Modal title='Edit Device' okText='save' onOk={handleSubmit(onSubmit)} visible={isVisible} onCancel={onClose} loading={submitting}>
      <form onSubmit={handleSubmit(onSubmit)} className='mb-6'>
        <FormField>
          <FormLabel mode='light' htmlFor='name'>
            Name
          </FormLabel>
          <FormFieldDescription>Your name of your Device</FormFieldDescription>
          <input
            className='appearance-none border rounded w-full py-2 px-3 text-gray-700 text-sm focus:outline-none focus:shadow-outline'
            type='text'
            {...register('name', { required: true, value: device?.name })}
          />
          {errors.name && <FormFieldError>{errors.name.message}</FormFieldError>}
        </FormField>
        <FormField>
          <FormLabel mode='light' htmlFor='token'>
            Token
          </FormLabel>
          <FormFieldDescription>Your Device token (DEVEUI)</FormFieldDescription>
          <input
            className='appearance-none border rounded w-full py-2 px-3 text-gray-700 text-sm focus:outline-none focus:shadow-outline'
            type='text'
            {...register('token', { required: true, value: device?.token })}
          />
          {errors.token && <FormFieldError>{errors.token.message}</FormFieldError>}
        </FormField>
      </form>
    </Modal>
  );
}

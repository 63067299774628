import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
  className?: string;
  children: ReactNode;
}

export default function FormField({ children, className }: Props): JSX.Element {
  return <div className={classNames('flex flex-col mt-4', className)}>{children}</div>;
}

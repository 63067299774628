import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';
import React from 'react';

interface Props {
  title: string | React.ReactNode;
}

const isIos = Capacitor.getPlatform() === 'ios';

export default function Header({ title }: Props): JSX.Element {
  return (
    <header className='mx-auto lg:w-3/4 relative'>
      <h1
        className={classNames('mt-safe text-3xl text-gray-50 flex items-center justify-center gap-x-1', {
          'my-7': !isIos,
          'mt-5 mb-7': isIos,
        })}
      >
        {title}
      </h1>
    </header>
  );
}

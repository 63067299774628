import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
  className?: string;
  children: ReactNode;
}

export default function FormFieldDescription({ children, className }: Props): JSX.Element {
  return <p className={classNames('text-gray-500 text-xs mb-2', className)}>{children}</p>;
}

import React from 'react';
import Skeleton from '../skeleton/Skeleton';
import ListItemWrapper from './ListItemWrapper';

export default function ListItemLoading(): JSX.Element {
  return (
    <ListItemWrapper>
      <div className='w-full flex justify-between'>
        <Skeleton className='h-4 w-1/2' />
        <Skeleton className='h-4 w-24' />
      </div>
    </ListItemWrapper>
  );
}

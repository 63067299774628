import React from 'react';
import Header from '../components/header/Header';

export default function NotFound(): JSX.Element {
  return (
    <>
      <Header title='Page not found' />

      <div className='mx-auto mb-5 lg:w-2/4 sm:w-3/4 rounded-sm bg-gray-50 p-5 mt-10'>
        <p className='text-base my-3'>Oeps, this page does not exists.</p>
      </div>
    </>
  );
}

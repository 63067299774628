import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import timezones from 'timezones-list';
import { inputClassesDark, inputWarningClass } from '../../helpers/CssClasses';
import { EMAIL_REGEX } from '../../helpers/Validators';
import useUser from '../../hooks/UseUser';
import { updateUser } from '../../services/User';
import { Alert } from '../alert/Alert';
import Button from '../Button';
import FormField from '../formElements/FormField';
import FormFieldDescription from '../formElements/FormFieldDescription';
import FormFieldError from '../formElements/FormFieldError';
import FormLabel from '../formElements/FormLabel';
import Select from '../formElements/Select';

export interface UserProfileFormModel {
  name: string;
  email: string;
  password?: string;
  password_confirmation?: string;
  timezone: string;
}

export default function EditProfile(): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { user, loadUser } = useUser();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<UserProfileFormModel>();

  const timezonesList = useMemo(
    () =>
      timezones
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }

          // names must be equal
          return 0;
        })
        .map(val => ({
          label: val.tzCode,
          value: val.tzCode,
        })),
    [],
  );

  // save the current password
  const password = useRef<string | undefined>('');
  password.current = watch('password', '');

  /**
   * Function that be called when submitting the form
   */
  const onSubmit = async ({ email, name, password, timezone }: UserProfileFormModel) => {
    setSubmitting(true);

    try {
      // change profile data
      await updateUser({
        name,
        email,
        password,
        timezone,
      });

      // reload the user
      await loadUser();

      setErrorMessage('');
      toast.success('Profile updated');
    } catch (error) {
      setErrorMessage(`Something went wrong: ${error}`);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!user) return;

    reset({
      timezone: user.timezone,
    });
  }, [user, reset]);

  return (
    <>
      {errorMessage && <Alert type='warning' title='Error' description={errorMessage} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField>
          <FormLabel mode='dark' htmlFor='name'>
            Name
          </FormLabel>
          <input
            className={classNames(inputClassesDark, { [inputWarningClass]: errors.name })}
            placeholder='John'
            type='text'
            {...register('name', { required: 'This field is required', value: user?.name })}
          />
          {errors.name && <FormFieldError>{errors.name.message}</FormFieldError>}
        </FormField>
        <FormField>
          <FormLabel mode='dark' htmlFor='email'>
            Email
          </FormLabel>
          <input
            className={classNames(inputClassesDark, { [inputWarningClass]: errors.email })}
            placeholder='john@doe.com'
            type='email'
            {...register('email', {
              value: user?.email,
              required: 'E-mail address is required',
              pattern: {
                value: EMAIL_REGEX,
                message: 'Incorrect e-mail address',
              },
            })}
          />
          {errors.email && <FormFieldError>{errors.email.message}</FormFieldError>}
        </FormField>
        <FormField>
          <FormLabel mode='dark' htmlFor='timezone'>
            Timezone
          </FormLabel>
          <FormFieldDescription>
            The timezone is needed to set the correct dates across the application. It also set the correct time for sending the push
            notifications
          </FormFieldDescription>
          <Select
            mode='dark'
            id='timezone'
            options={timezonesList}
            className={classNames({ [inputWarningClass]: errors.name })}
            {...register('timezone', { required: 'this field is required' })}
          />
          {errors.timezone && <FormFieldError>{errors.timezone.message}</FormFieldError>}
        </FormField>

        <fieldset className='mt-5'>
          <legend className='mb-2 pb-2 border-b block w-full text-gray-200'>Password</legend>
          <FormField>
            <FormLabel mode='dark' htmlFor='password'>
              New Password
            </FormLabel>
            <input
              className={classNames(inputClassesDark, { [inputWarningClass]: errors.password })}
              type='password'
              {...register('password')}
            />
          </FormField>
          <FormField>
            <FormLabel mode='dark' htmlFor='password_confirmation'>
              Password confirmation
            </FormLabel>
            <input
              className={classNames(inputClassesDark, { [inputWarningClass]: errors.password_confirmation })}
              type='password'
              {...register('password_confirmation', {
                validate: value => {
                  return value === password.current || password.current === undefined || 'The passwords do not match';
                },
              })}
            />
            {errors.password_confirmation && <FormFieldError>{errors.password_confirmation.message}</FormFieldError>}
          </FormField>
        </fieldset>

        <div className='mt-5'>
          <Button type='primary' htmlType='submit' loading={submitting}>
            Save
          </Button>
        </div>
      </form>
    </>
  );
}

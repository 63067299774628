import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
  htmlFor?: string;
  className?: string;
  mode?: 'dark' | 'light';
  children: ReactNode;
}

export default function FormLabel({ children, htmlFor, className, mode = 'light' }: Props): JSX.Element {
  return (
    <label
      className={classNames('block  text-xs mb-2 font-semibold', className, {
        'text-gray-700': mode === 'light',
        'text-gray-200': mode === 'dark',
      })}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
}

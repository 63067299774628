import classNames from 'classnames';
import React from 'react';

interface Props {
  title: string;
  description?: string;
  type: 'notice' | 'warning' | 'danger' | 'success';
}

export function Alert({ title, description, type }: Props): JSX.Element {
  const alertClasses = classNames({
    'bg-yellow-100': type === 'warning',
    'text-yellow-700 ': type === 'warning',
    'border-yellow-500': type === 'warning',
    'bg-green-100': type === 'success',
    'text-green-700 ': type === 'success',
    'border-green-500': type === 'success',
    'bg-blue-100': type === 'notice',
    'text-blue-700 ': type === 'notice',
    'border-blue-500': type === 'notice',
    'bg-red-100': type === 'danger',
    'text-red-700 ': type === 'danger',
    'border-red-500': type === 'danger',
  });

  return (
    <div className={` border-l-4  p-4 ${alertClasses} my-2`} role='alert'>
      <p className='font-bold'>{title}</p>
      {description && <p className={'text-sm font-light'}>{description}</p>}
    </div>
  );
}

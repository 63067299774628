import React from 'react';

function NoData(): JSX.Element {
  return (
    <div className='mx-auto mb-5 lg:w-2/4 sm:w-3/4 rounded-sm bg-gray-50 p-5 mt-10'>
      <span className='font-semibold text-base'>It seems you did not push any solar data</span>
      <ol className='text-sm list-decimal pl-5 mt-5'>
        <li>Create a Device</li>
        <li>Whitelist your IP from where you are making the request</li>
        <li>Use the follow snippet to push data</li>
      </ol>
      <pre className='mt-5 text-xs'>
        {`const axios = require('axios');

const {data} = await axios.post('https://api.solar-daytopper.com/device/login', {
  token: 'your-device-token'
});

await axios.post('https://api.solar-daytopper.com/solar', {
    total: 9000,
    current: 200,
  }, {
    headers: {
    Authorization: 'Bearer ' + data.token
  }
});
`}
      </pre>
    </div>
  );
}

export default NoData;

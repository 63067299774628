import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';

const config: AxiosRequestConfig = {
  baseURL: process.env.NODE_ENV === 'production' && process.env.API_HOST ? process.env.API_HOST : '/api',
};

const httpClient = axios.create(config);

/** Auth token interceptors */
const authInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  // when the Authorization head is set manually, bail out
  if (config.headers && config.headers.Authorization) {
    return config;
  }

  const token = localStorage.getItem('token');
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor);

/** Add JWT expired redirect */
httpClient.interceptors.response.use(
  response => response,
  async error => {
    const originalConfig = error.config;

    if (error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;

      try {
        const { data } = await httpClient.post('/user/refresh_token', {
          refresh_token: localStorage.getItem('refreshToken'),
        });

        // set the new tokens
        localStorage.setItem('token', data.token as string);
        localStorage.setItem('refreshToken', data.refreshToken as string);

        originalConfig.headers = {
          ...config.headers,
          authorization: `Bearer ${data.accessToken}`,
        };

        return httpClient(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }

    // return just the message of the error instead of a full object
    return Promise.reject(new CustomHttpError(error.response.data, error.message, error.response.status));
  },
);

export class CustomHttpError extends Error {
  public statusMessage: string;
  public statusCode: number;

  constructor(message: string, statusMessage: string, statusCode: number) {
    super(message);
    this.statusMessage = statusMessage;
    this.statusCode = statusCode;
  }
}

export { httpClient };

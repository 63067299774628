import keccak256 from 'keccak256';
import { User } from '../context/UserContext';
import { CustomHttpError, httpClient } from '../helpers/httpClient';

export interface UserRegister {
  name: string;
  email: string;
  password: string;
  timezone: string;
}

export interface UserLoginResponse {
  user: User;
  token: string;
  refreshToken: string;
}

export interface UpdateUserRequest {
  email?: string;
  password?: string;
  password_confirmation?: string;
  timezone: string;
}

export async function signup(name: string, email: string, password: string, timezone: string): Promise<void> {
  try {
    const hashedPassword = keccak256(password).toString('base64');

    await httpClient.post<UserLoginResponse>('/user/signup', {
      name,
      email,
      password: hashedPassword,
      timezone,
    });

    return Promise.resolve();
  } catch (e) {
    if (e instanceof CustomHttpError || e instanceof Error) {
      return Promise.reject(e.message);
    }

    return Promise.reject('unknown error');
  }
}

export async function signupComplete(token: string): Promise<void> {
  try {
    await httpClient.post<UserLoginResponse>('/user/signup/complete', {
      token,
    });

    return Promise.resolve();
  } catch (e) {
    if (e instanceof CustomHttpError || e instanceof Error) {
      return Promise.reject(e.message);
    }

    return Promise.reject('unknown error');
  }
}

export async function login(email: string, password: string): Promise<User> {
  try {
    const {
      data: { user, token, refreshToken },
    } = await httpClient.post<UserLoginResponse>('/user/login', {
      email,
      password,
    });

    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);

    return Promise.resolve(user);
  } catch (e) {
    if (e instanceof CustomHttpError || e instanceof Error) {
      return Promise.reject(e.message);
    }

    return Promise.reject('unknown error');
  }
}

export async function getUser(signal?: AbortSignal): Promise<User> {
  try {
    const { data } = await httpClient.get<User>('/user', { signal });

    return Promise.resolve(data);
  } catch (e) {
    if (e instanceof CustomHttpError || e instanceof Error) {
      return Promise.reject(e.message);
    }

    return Promise.reject('unknown error');
  }
}

export async function updateUser({
  name,
  email,
  password,
  timezone,
}: Pick<User, 'email' | 'name' | 'timezone'> & { password?: string }): Promise<User> {
  try {
    const { data: response } = await httpClient.patch<User>('/user/', {
      name,
      email,
      password: password ? keccak256(password).toString('base64') : null,
      timezone,
    });

    return Promise.resolve(response);
  } catch (e) {
    if (e instanceof CustomHttpError || e instanceof Error) {
      return Promise.reject(e.message);
    }

    return Promise.reject('unknown error');
  }
}

export async function sendAccountDeletionRequest(reason: string): Promise<void> {
  try {
    await httpClient.delete('/user/', {
      data: {
        reason,
      },
    });

    return Promise.resolve();
  } catch (e) {
    if (e instanceof CustomHttpError || e instanceof Error) {
      return Promise.reject(e.message);
    }

    return Promise.reject('unknown error');
  }
}

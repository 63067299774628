import { Capacitor } from '@capacitor/core';
import homeOutlined from '@iconify/icons-ant-design/home-outlined';
import keyOutlined from '@iconify/icons-ant-design/key-outlined';
import logoutOutlined from '@iconify/icons-ant-design/logout-outlined';
import userOutlined from '@iconify/icons-ant-design/user-outlined';
import { Icon, IconifyIcon } from '@iconify/react';
import classNames from 'classnames';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserObject } from '../../context/UserContext';
import useModal from '../../hooks/UseModal';
import DeviceModal from '../device/DeviceModal';
import { Confirm } from '../Modal/ConfirmModal';

const isIos = Capacitor.getPlatform() === 'ios';

interface MenuItem {
  label: string;
  icon: IconifyIcon;
  onClick: () => void;
  isActive?: boolean;
  showIconAndLabelOnWeb?: boolean;
}

export default function Menu(): JSX.Element {
  const navigate = useNavigate();

  const { modalIsVisible: deviceModalIsVisible, showModal: showDeviceModal, closeModal: closeDeviceModal } = useModal();

  const { setUser } = useUserObject();
  const location = useLocation();

  const logout = () => {
    Confirm({
      title: 'Logout',
      text: '  Are you sure you want to logout?',
      type: 'danger',
      confirmText: 'Logout',
      onConfirm: () => {
        localStorage.setItem('token', '');
        localStorage.setItem('refreshToken', '');
        setUser(null);
      },
    });
  };

  const menuItems: MenuItem[] = [
    {
      label: 'Home',
      icon: homeOutlined,
      isActive: location.pathname === '/',
      onClick: () => navigate('/'),
    },
    {
      label: 'Profile',
      icon: userOutlined,
      isActive: location.pathname === '/profile',
      onClick: () => navigate('/profile'),
    },
    {
      label: 'Devices',
      icon: keyOutlined,
      onClick: () => showDeviceModal(),
    },
    {
      label: 'Logout',
      icon: logoutOutlined,
      onClick: () => logout(),
      showIconAndLabelOnWeb: true,
    },
  ];

  return (
    <>
      {/* TODO show back button */}
      {/* {groupId && user && user.groups.length > 0 && (
        <div
          className={classNames('hover:text-gray-200 text-lg text-gray-400 absolute left-2 z-[60]', {
            'top-2': !isIos,
            'top-14': isIos,
          })}
        >
          <Link to='/groups' className={'pl-1 cursor-pointer text-sm flex items-center gap-2'}>
            <Icon icon={arrowLeftOutlined} />
            <span
              className={classNames({
                'sm:block hidden': !isIos,
              })}
            >
              Back
            </span>
          </Link>
        </div>
      )} */}

      {isIos && <div className='fixed z-999 top-0 w-full bg-[#2a2a2b] h-[calc(env(safe-area-inset-top))]' />}
      <nav
        className={classNames('w-full z-50', {
          'absolute top-0': !isIos,
          'fixed bottom-0 bg-[#2a2a2b]': isIos,
        })}
      >
        <ul
          className={classNames('flex px-4', {
            'justify-around divide-x divide-gray-800 bg-dark-menu border-t border-gray-700': isIos,
            'lg:justify-end justify-center pt-2': !isIos,
          })}
        >
          {menuItems.map(menuItem => (
            <li
              onClick={menuItem.onClick}
              key={menuItem.label}
              className={classNames('text-lg', {
                'text-gray-400 w-1/3': isIos,
                'text-gray-400 mr-3 hover:text-gray-200': !isIos,
                '!text-primary': isIos && menuItem.isActive,
                '!text-gray-200': !isIos && menuItem.isActive,
              })}
            >
              <div
                className={classNames('cursor-pointer flex items-center ', {
                  'px-1 py-5 w-full flex justify-center text-sm': isIos,
                  'sm:text-sm text-xs': !isIos,
                })}
              >
                {!isIos && (
                  <>
                    <span className='pr-1'>{menuItem.label}</span>
                    {menuItem.showIconAndLabelOnWeb && (
                      <span className='text-lg'>
                        <Icon width={15} height={15} icon={menuItem.icon} />
                      </span>
                    )}
                  </>
                )}

                {isIos && (
                  <div className='flex flex-col items-center'>
                    <span className='text-xl'>
                      <Icon icon={menuItem.icon} />
                    </span>
                    <span className='text-xs'>{menuItem.label}</span>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </nav>

      <DeviceModal onCloseModal={closeDeviceModal} showModal={deviceModalIsVisible} />
    </>
  );
}

import checkOutlined from '@iconify/icons-ant-design/check-outlined';
import copyOutlined from '@iconify/icons-ant-design/copy-outlined';
import { Icon } from '@iconify/react';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '../Tooltip';
import CopyAsInput from './CopyAsInput';
import CopyAsSpan from './CopyAsSpan';

interface Props {
  value: string;
  className?: string;
  elementClassName?: string;
  buttonClassName?: string;
  autoSelect?: boolean;
  onCopied?: () => void;
  bordered?: boolean;
  focus?: boolean;
  elementType?: 'span' | 'input';
  iconSize?: number;
  children?: React.ReactNode;
}

export default function Copy({
  value,
  className,
  elementClassName,
  buttonClassName,
  autoSelect,
  onCopied,
  bordered = true,
  focus = true,
  elementType,
  iconSize,
  children,
}: Props): JSX.Element {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const element = useRef<HTMLInputElement>(null);

  const selectSpanElement = () => {
    if (element.current) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(element.current);

      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  /**
   * Callback on copied
   */
  const onCopy = () => {
    if (elementType === 'span') {
      selectSpanElement();
    } else {
      element.current?.select();
    }

    setShowTooltip(true);
    onCopied?.();
  };

  /**
   * set a timeout to reset the state of the tooltip, so it will hide after 2s
   */
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    if (showTooltip) {
      timer = setTimeout(() => setShowTooltip(false), 2000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [showTooltip]);

  /**
   * on show, select the text so the user can copy it also manual
   */
  useEffect(() => {
    if (autoSelect) {
      if (elementType === 'span') {
        selectSpanElement();
      } else {
        element.current?.select();
      }
    }
  }, [autoSelect, elementType]);

  return (
    <CopyToClipboard text={value} onCopy={onCopy}>
      <div
        className={classNames(className, {
          'flex items-center rounded cursor-pointer': children === undefined,
          'border py-1 px-1': bordered && children === undefined,
        })}
      >
        {elementType === 'span' && <CopyAsSpan ref={element} value={value} elementClassName={elementClassName} bordered={bordered} />}
        {elementType === 'input' && (
          <CopyAsInput ref={element} focus={focus} value={value} elementClassName={elementClassName} bordered={bordered} />
        )}
        <Tooltip
          visible={showTooltip}
          content={
            <div className='flex items-center'>
              <Icon icon={checkOutlined} className='mr-1' /> copied!
            </div>
          }
          mode='dark'
          arrow={false}
          placement='left'
        >
          <div>
            {children === undefined && (
              <button
                className={classNames(buttonClassName, {
                  'ml-1': elementType === 'span',
                })}
              >
                <Icon icon={copyOutlined} width={iconSize} />
              </button>
            )}

            {children}
          </div>
        </Tooltip>
      </div>
    </CopyToClipboard>
  );
}

import React from 'react';
import Spinner from './Spinner';

export default function SpinnerFullPage(): JSX.Element {
  return (
    <div className='mt-32 flex justify-center'>
      <Spinner size='full-page' />
    </div>
  );
}

import classNames from 'classnames';
import React, { ForwardedRef } from 'react';

interface Props {
  value: string;
  elementClassName: string | undefined;
  bordered: boolean | undefined;
  focus: boolean | undefined;
}

const CopyAsInput = React.forwardRef(function CopyAsInput(
  { focus, elementClassName, value, bordered }: Props,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  return (
    <input
      autoFocus={focus}
      readOnly={true}
      ref={ref}
      className={classNames('inline-block w-full cursor-pointer text-sm outline-none bg-transparent', elementClassName, {
        'font-mono px-1': bordered,
        truncate: !bordered,
      })}
      value={value}
    />
  );
});

export default CopyAsInput;

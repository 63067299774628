import 'react-toastify/dist/ReactToastify.css';
import { StatusBar, Style } from '@capacitor/status-bar';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginForm from './components/forms/LoginForm';
import RegisterForm from './components/forms/RegisterForm';
import AuthWrapper from './components/wrappers/AuthWrapper';
import DefaultWrapper from './components/wrappers/DefaultWrapper';
import RouteWrapper from './components/wrappers/RouteWrapper';
import useUser from './hooks/UseUser';
import Home from './pages/Home';
import Maps from './pages/Maps';
import NotFound from './pages/NotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Profile from './pages/Profile';

export default function App(): JSX.Element {
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [initialLoaded, setInitialLoaded] = useState<boolean>(false);

  const { user, loadUser, loading } = useUser();

  /**
   * set the style for an IOS device
   */
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    StatusBar.setStyle({ style: Style.Dark }).catch(() => {});
  }, []);

  /**
   * Fetch the user data at first
   * - hit an error? Then show the login
   * - if fetched the user, go further and show the data
   */
  useEffect(() => {
    const abortController = new AbortController();
    loadUser(abortController.signal)
      .catch(() => setShowLogin(true))
      .finally(() => setInitialLoaded(true));

    return () => abortController.abort();
  }, [loadUser, setShowLogin, setInitialLoaded]);

  /**
   * If the user object got changed by the logout (or...)
   * show the login so the user can login again.
   */
  useEffect(() => {
    if (user) {
      setShowLogin(false);
    } else {
      setShowLogin(true);
    }
  }, [user]);

  /**
   * show the login form in needed
   */
  if (showLogin && !loading) {
    return (
      <Routes>
        <Route element={<DefaultWrapper />}>
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        </Route>
        <Route element={<AuthWrapper />}>
          <Route path='/signup' element={<RegisterForm />} />
          <Route path='*' element={<LoginForm />} />
        </Route>
      </Routes>
    );
  }

  /**
   * Show the app
   */
  if (initialLoaded) {
    return (
      <Routes>
        <Route element={<RouteWrapper />}>
          <Route path='/' element={<Home />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/map/:deviceId' element={<Maps />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    );
  }

  return <></>;
}

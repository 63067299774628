import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';

interface Props {
  id?: string;
  inverse?: boolean;
  active?: boolean;
  size?: 'sm' | 'base';
  onToggle?: (active: boolean) => void;
}

export default function Toggle({ id = `toggle_${nanoid()}`, active = false, size = 'base', onToggle }: Props): JSX.Element {
  const [toggleActive, setToggleActive] = useState<boolean>(active);

  /**
   * When the user toggled the checkbox
   */
  const onChange = (): void => {
    setToggleActive(prevState => !prevState);
    onToggle?.(!toggleActive);
  };

  useEffect(() => {
    setToggleActive(active);
  }, [active]);

  return (
    <div
      className={classNames('relative inline-block align-middle select-none transition duration-200 ease-in', {
        'w-10': size === 'base',
        'w-8': size === 'sm',
      })}
    >
      <input
        onChange={onChange}
        type='checkbox'
        name={id}
        id={id}
        className={classNames('absolute block rounded-full border-4 appearance-none cursor-pointer bg-white transition-all', {
          'w-6 h-6': size === 'base',
          'w-5 h-5': size === 'sm',
          'right-0 border-blue-600': toggleActive,
          'border-gray-300': !toggleActive,
        })}
      />
      <label
        htmlFor={id}
        className={classNames('block overflow-hidden rounded-full cursor-pointer', {
          'h-6': size === 'base',
          'h-5': size === 'sm',
          'bg-gray-300 ': !toggleActive,
          'bg-blue-600': toggleActive,
        })}
      />
    </div>
  );
}

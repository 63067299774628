import closeOutlined from '@iconify/icons-ant-design/close-outlined';
import { Icon } from '@iconify/react';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useEffect, useRef, useState } from 'react';
import * as ReactIs from 'react-is';
import { Portal } from 'react-portal';
import { useDialogContext } from '../../context/DialogContext';
import useKeypress from '../../hooks/UseKeypress';
import Button from '../Button';
import Spinner from '../spinner/Spinner';

export enum ModalWidth {
  xs = 'w-full xs:w-modal-xs',
  sm = 'w-full xs:w-modal-xs sm:w-modal-sm',
  md = 'w-full xs:w-modal-xs sm:w-modal-sm',
  lg = 'w-full xs:w-modal-xs sm:w-modal-sm md:w-modal-lg',
}

interface Props {
  title?: string;
  danger?: boolean;
  showButtons?: boolean;
  showSubmitButton?: boolean;
  showSecondaryButton?: boolean;
  visible?: boolean;
  cancelText?: string;
  okText?: string;
  secondaryButtonText?: string;
  onOpen?: () => void;
  onCancel?: () => void;
  onClosed?: () => void;
  onOk?: () => void;
  onSecondaryButtonClick?: () => void;
  showCloseIcon?: boolean;
  closable?: boolean;
  loading?: boolean;
  disabledSubmit?: boolean;
  disabledSecondaryButton?: boolean;
  width?: ModalWidth;
  fixedHeight?: boolean;
  setMaxHeight?: boolean;
  className?: string;
  fixedHeaderComponent?: React.ReactNode;
  showFixedHeaderComponent?: boolean;
  fixedFooterComponent?: React.ReactNode;
  helpText?: string;
  sideNode?: React.ReactNode;
  children: React.ReactNode;
  footerSideNode?: React.ReactNode;
  htmlForm?: string;
  fullscreen?: boolean;
  // make sure the size of the modal keep the same on larger screens and not growing into the space that is left
  remainSizeOnSmallerScreens?: boolean;
  enableOverlay?: boolean;
  loadingContent?: boolean;
}

export default function Modal({
  title,
  danger,
  showButtons = true,
  showSubmitButton = true,
  showSecondaryButton = true,
  visible = false,
  cancelText = 'Cancel',
  okText = 'Save',
  secondaryButtonText,
  onCancel,
  onOk,
  onOpen,
  onClosed,
  onSecondaryButtonClick,
  showCloseIcon = true,
  closable = true,
  sideNode,
  children,
  loading = false,
  disabledSubmit = false,
  disabledSecondaryButton = false,
  width = ModalWidth.xs,
  fixedHeight = false,
  setMaxHeight = true,
  className,
  fixedHeaderComponent,
  showFixedHeaderComponent = true, // Because the fixedHeaderComponent can deal with an empty fragment, we cannot check on emptiness of fixedHeaderComponent
  fixedFooterComponent,
  helpText,
  footerSideNode,
  htmlForm,
  fullscreen,
  remainSizeOnSmallerScreens,
  enableOverlay = true,
  loadingContent = false,
}: Props): JSX.Element {
  const [animationBackgroundClass, setAnimationBackgroundClass] = useState<'animate-fadeIn' | 'animate-fadeOut'>('animate-fadeIn');
  const [animationModalClass, setAnimationModalClass] = useState<'animate-zoomIn' | 'animate-zoomOut'>('animate-zoomIn');
  const [animationSpeedClass, setAnimationSpeedClass] = useState<'animate-faster' | 'animate-fast'>('animate-faster');
  const [isVisible, setIsVisible] = useState<boolean>(visible);

  const { setDialogsIds, lastDialogId } = useDialogContext();

  const dialogId = useRef<string>(nanoid());

  // when using the htmlForm property, we should omit the onOk fn.
  if (htmlForm && onOk) {
    console.warn('[Modal]: You are mixing both the "onOk" and the "htmlForm" property. This will lead to submitting a form twice.');
  }

  /**
   * When the modal is set to visible, apply animations and set the local state for showing the modal
   */
  useEffect(() => {
    // when visible, set the modal as visible and apply the correct animations
    if (visible && !isVisible) {
      setIsVisible(true);
      setAnimationSpeedClass('animate-faster');
      setAnimationModalClass('animate-zoomIn');
      setAnimationBackgroundClass('animate-fadeIn');
      // add the id to the list of dialogs that are open
      setDialogsIds(prevState => [...new Set([...prevState, dialogId.current])]);

      // Cause we set the animation speed to .animate-faster we are sure that the animation is end withing .4 sec
      // We tried before using the onAnimationEnd event, but that create a loop where it close all modals
      // TODO find out how to use onAnimationEnd correctly without closing all modals
      setTimeout(() => {
        onOpen?.();
      }, 400);
    } else if (!visible && isVisible) {
      // when the user set the modal as closed
      // apply the correct animations and execute the cleanup function
      setAnimationSpeedClass('animate-fast');
      setAnimationModalClass('animate-zoomOut');
      setAnimationBackgroundClass('animate-fadeOut');
      // remove the current ID of the openedDialogs
      setDialogsIds(prevState => prevState.filter(id => id !== dialogId.current));

      // Cause we set the animation speed to .animate-faster we are sure that the animation is end withing .4 sec
      // We tried before using the onAnimationEnd event, but that create a loop where it close all modals
      // TODO find out how to use onAnimationEnd correctly without closing all modals
      setTimeout(() => {
        setIsVisible(false);
        onClosed?.();
      }, 400);
    }
  }, [isVisible, setDialogsIds, visible]); //eslint-disable-line

  /**
   * Support to close the modal with the ESC key
   */
  useKeypress('Escape', () => {
    if (closable && lastDialogId && lastDialogId === dialogId.current) {
      onCancel?.();
    }
  });

  return (
    <>
      {isVisible && (
        <Portal node={document && document.getElementById('root')}>
          <div
            className='fixed flex sm:justify-center sm:items-center z-[100] inset-0 overflow-y-auto p-safe'
            aria-labelledby='modal-title'
            role='dialog'
            aria-modal='true'
          >
            <div
              className={classNames('block sm:p-0', {
                'py-[calc(16px+env(safe-area-inset-top))] sm:py-4 px-4 flex w-full sm:w-auto': !fullscreen,
                // make sure the size of the modal keep the same on larger screens and not growing into the space that is left
                'items-center': remainSizeOnSmallerScreens,
              })}
            >
              {/*Create a gray background as overlay*/}
              {enableOverlay && (
                <div
                  className={classNames(
                    animationBackgroundClass,
                    animationSpeedClass,
                    'modal-background fixed inset-0 bg-gray-500 bg-opacity-75',
                  )}
                  aria-hidden='true'
                />
              )}

              {/*This element is to trick the browser into centering the modal contents.*/}
              {/* <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
                &#8203;
              </span> */}

              <div
                className={classNames(
                  'modal',
                  animationModalClass,
                  animationSpeedClass,
                  width,
                  'flex flex-col grow sm:inline-block relative bg-white rounded-md text-left overflow-hidden shadow-xl',
                  {
                    '!w-screen !h-screen overflow-hidden': fullscreen,
                    'sm:my-8 ': !fullscreen,
                  },
                )}
              >
                {(showCloseIcon || closable) && (
                  <button
                    onClick={onCancel}
                    disabled={loading}
                    className={classNames('absolute z-40 right-3 top-4 focus:outline-none', {
                      'cursor-not-allowed': loading,
                    })}
                  >
                    <Icon icon={closeOutlined} />
                  </button>
                )}

                {/*This element is the head of the modal*/}
                {title && (
                  <div
                    className={classNames('w-full bg-white px-4 pt-2 relative z-30 h-[50px]', {
                      'shadow-sm': !fixedHeaderComponent || !showFixedHeaderComponent,
                      'border-gray-100 border-b': fixedHeaderComponent && showFixedHeaderComponent,
                    })}
                  >
                    <h3 className='text-xl leading-10 font-medium text-gray-900 ' id='modal-title'>
                      {title}
                    </h3>
                  </div>
                )}

                <div
                  className={classNames('relative z-20 bg-white overflow-y-auto overflow-x-hidden', className, {
                    'sm:max-h-[30rem]': !fullscreen && setMaxHeight,
                    'mt-5': !title,
                    'sm:h-[30rem] h-[20rem]': !fullscreen && fixedHeight,
                    'w-full h-full': fullscreen,
                  })}
                >
                  {/*This is a fixed header component, stays on top of the scrollable content*/}
                  {fixedHeaderComponent && showFixedHeaderComponent && (
                    <div className='sticky top-0 bg-white z-10 w-full px-4 py-2 shadow-sm border-gray-100 border-b'>
                      {fixedHeaderComponent}
                    </div>
                  )}

                  <div
                    className={classNames('sm:flex sm:items-start', {
                      'mb-[3.7rem]': fixedFooterComponent && !ReactIs.isFragment(fixedFooterComponent),
                      'w-full h-full': fullscreen,
                      'p-4 pt-1': !fullscreen,
                    })}
                  >
                    {sideNode && <div className='mt-2'>{sideNode}</div>}
                    <div
                      className={classNames('w-full sm:text-left', {
                        'h-full': fullscreen,
                      })}
                    >
                      {/*This element is the body of the modal*/}
                      <div
                        className={classNames('text-base relative', {
                          'w-full h-full': fullscreen,
                          'mt-2': !fullscreen,
                        })}
                      >
                        {loadingContent && (
                          <div className='flex justify-center'>
                            <Spinner inverseColor={true} size='large' />
                          </div>
                        )}
                        {!loadingContent && children}
                      </div>
                    </div>
                  </div>
                </div>

                {/*This is a fixed header component, stays on top of the scrollable content*/}
                {fixedFooterComponent && !ReactIs.isFragment(fixedFooterComponent) && (
                  <div className='absolute bottom-[3.7rem] bg-white z-20 w-full px-4 py-2 border-gray-100 border-t'>
                    {fixedFooterComponent}
                  </div>
                )}

                {/*This element is the footer of the modal*/}
                {showButtons && (
                  <div className='mt-auto sm:mt-0 border-t border-gray-100 bg-gray-50 px-4 py-3 sm:px-4 sm:flex sm:flex-row-reverse items-center justify-between'>
                    <div className='sm:flex items-center justify-end'>
                      <Button type='link' onClick={onCancel} disabled={loading} className='mb-3 sm:mb-0 sm:mr-1 rounded-md'>
                        {cancelText}
                      </Button>

                      {showSecondaryButton && onSecondaryButtonClick && secondaryButtonText && (
                        <Button disabled={disabledSecondaryButton} onClick={onSecondaryButtonClick} className='mb-3 sm:mb-0 sm:mr-1'>
                          {secondaryButtonText}
                        </Button>
                      )}

                      {showSubmitButton && (
                        <Button
                          htmlForm={htmlForm}
                          disabled={disabledSubmit}
                          loading={loading}
                          onClick={onOk}
                          type={danger ? 'danger' : 'primary'}
                        >
                          {okText}
                        </Button>
                      )}
                    </div>

                    {footerSideNode && <div className='mt-2 sm:mt-0'>{footerSideNode}</div>}

                    {!footerSideNode && helpText && <span className='text-xs text-gray-400'>{helpText}</span>}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
}

import deleteOutlined from '@iconify/icons-ant-design/delete-outlined';
import editOutlined from '@iconify/icons-ant-design/edit-outlined';
import eyeOutlined from '@iconify/icons-ant-design/eye-outlined';
import { Icon } from '@iconify/react';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useModal from '../../hooks/UseModal';
import { Device, generateDevice, listDevices, removeDevice, resetDeviceToken } from '../../services/Device';
import Button from '../Button';
import Copy from '../Copy';
import FormFieldDescription from '../formElements/FormFieldDescription';
import FormLabel from '../formElements/FormLabel';
import Modal from '../Modal';
import { Confirm } from '../Modal/ConfirmModal';
import EditDeviceModal from './EditDeviceModal';

interface Props {
  showModal: boolean;
  onCloseModal: () => void;
}

function DeviceModal({ showModal, onCloseModal }: Props): JSX.Element {
  const [loadingContent, setLoadingContent] = useState<boolean>(false);
  const [createDeviceLoading, setCreateDeviceLoading] = useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<Device | undefined>();
  const [openDevice, setOpenDevice] = useState<Device | undefined>();
  const [devices, setDevices] = useState<Device[]>([]);

  const { modalIsVisible: editModalIsVisible, showModal: showEditModal, closeModal: closeEditModal } = useModal();

  const loadDevices = useCallback(async () => {
    setLoadingContent(true);

    try {
      const loadedDevices = await listDevices();
      const formattedDevices: Device[] = loadedDevices.map(key => ({
        ...key,
        open: loadedDevices.length === 1,
      }));

      setDevices(formattedDevices);
    } catch (error) {
      toast.error('Cannot load the devices');
      console.error(error);
    } finally {
      setLoadingContent(false);
    }
  }, []);

  const remove = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, device: Device) => {
    e.stopPropagation();

    Confirm({
      title: 'Remove device',
      text: 'Are you sure you want to remove this device and all the history that it contains?',
      type: 'danger',
      onConfirm: async () => {
        try {
          await removeDevice(device.id);
          toast.success('Successfully removed Device');
          loadDevices();
        } catch (e) {
          toast.error('Failed to remove Device');
          console.error(e);
        }
      },
    });
  };

  const onResetDeviceToken = (device: Device) => {
    Confirm({
      title: 'Reset device token',
      text: 'Are you sure you want to reset this key?',
      type: 'danger',
      onConfirm: async () => {
        try {
          await resetDeviceToken(device.id);
          toast.success('Successfully reset the Device Token');
          loadDevices();
        } catch (e) {
          toast.error('Failed to reset the Device Token');
          console.error(e);
        }
      },
    });
  };

  const onEdit = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, device: Device) => {
    e.stopPropagation();
    showEditModal();
    setSelectedDevice(device);
  };

  const hideEditModal = () => {
    closeEditModal();
    setSelectedDevice(undefined);
  };

  const createDevice = async () => {
    setCreateDeviceLoading(true);
    try {
      await generateDevice();
      toast.success('Successfully created a Device');
      loadDevices();
    } catch (error) {
      toast.error('Failed to created a new Device');
    } finally {
      setCreateDeviceLoading(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      loadDevices();
    }
  }, [loadDevices, showModal]);

  return (
    <Modal
      title='Device'
      loadingContent={loadingContent}
      onCancel={onCloseModal}
      visible={showModal}
      loading={createDeviceLoading}
      showCloseIcon={false}
      onOk={createDevice}
      okText='Add new device'
    >
      {devices.map(device => (
        <div key={device.id} className='mb-5 border-gray-200'>
          <div className='py-2 font-bold flex items-center border-b hover:bg-gray-50 cursor-pointer' onClick={() => setOpenDevice(device)}>
            <span>{device.name}</span>
            <div className='ml-auto flex'>
              <Icon icon={eyeOutlined} className='mr-2' />
              <Icon icon={editOutlined} className='mr-2' onClick={e => onEdit(e, device)} />
              <Icon icon={deleteOutlined} onClick={e => remove(e, device)} />
            </div>
          </div>
          <div className={classNames('break-words bg-white py-2', { hidden: !openDevice || device.id !== openDevice.id })}>
            <div className='mb-3'>
              <FormLabel>Token</FormLabel>
              <FormFieldDescription>This token is needed to login with your Solar device</FormFieldDescription>
              <Copy elementType='input' value={device.token} />
            </div>

            <span className='block my-2 text-xs text-blue-500'>
              Last activity: {device.lastActivity !== null ? device.lastActivity : 'n/a'}
            </span>
            <Button className='mt-4' type='primary' size='small' onClick={() => onResetDeviceToken(device)}>
              Reset Token
            </Button>
          </div>
        </div>
      ))}

      <EditDeviceModal device={selectedDevice} showModal={editModalIsVisible} onClose={hideEditModal} onSuccess={loadDevices} />
    </Modal>
  );
}

export default DeviceModal;

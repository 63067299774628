import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import useUser from '../../hooks/UseUser';
import { Coordinate } from '../../services/Coordinates';

interface CoordinatesChart {
  fakeVal: number;
  time: number;
  coordinate: Coordinate;
}

interface Props {
  coordinates: Coordinate[];
  onHoverEnter: (coordinate: Coordinate) => void;
  onHoverLeave: () => void;
}

const isIos = Capacitor.getPlatform() === 'ios';

export default function Chart({ coordinates, onHoverEnter, onHoverLeave }: Props): JSX.Element {
  const [from, setFrom] = useState<number>(0);
  const [to, setTo] = useState<number>(0);

  const { user: currentUser } = useUser();

  const chartCoordinates: CoordinatesChart[] = coordinates.map(coordinate => ({
    coordinate,
    fakeVal: 0,
    time: DateTime.fromISO(coordinate.date).setZone(currentUser?.timezone).toSeconds(),
  }));

  useEffect(() => {
    if (coordinates.length > 0) {
      setFrom(DateTime.fromISO(coordinates[0].date).setZone(currentUser?.timezone).toSeconds());
      setTo(DateTime.fromISO(coordinates.slice(-1)[0].date).setZone(currentUser?.timezone).toSeconds());
    }
  }, [coordinates, currentUser]);

  // eslint-disable-next-line
  const formatXAxisLabel = (time: any) => {
    if (typeof time !== 'number') {
      return '';
    }

    return DateTime.fromSeconds(time).setZone(currentUser?.timezone).toFormat('HH:mm:ss');
  };

  if (!currentUser) return <></>;

  return (
    <>
      <ResponsiveContainer width={'99%'} height={100} className={classNames({ 'mb-20': isIos })}>
        <LineChart
          onMouseMove={e => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (e.activePayload && e.activePayload[0].payload && e.activePayload[0].payload.coordinate) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const payload: Coordinate = e.activePayload[0].payload.coordinate;
              onHoverEnter(payload);
              // L.marker([payload.lat, payload.lng]).addTo(map);
            }
          }}
          onMouseLeave={onHoverLeave}
          width={1900}
          data={chartCoordinates}
          margin={{
            top: 5,
            right: 20,
            left: 20,
            bottom: 5,
          }}
        >
          <Tooltip
            cursor={{ stroke: 'red', strokeWidth: 2 }}
            content={e => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (e.payload && e.payload[0] && e.payload[0].payload && e.payload[0].payload.coordinate) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const payload: Coordinate = e.payload[0].payload.coordinate;
                const date = DateTime.fromISO(payload.date).setZone(currentUser?.timezone).toLocaleString(DateTime.DATETIME_FULL);
                return <div className='py-1 px-2 bg-white text-sm'>{date}</div>;
              }

              return <></>;
            }}
          />

          <XAxis
            // tickMargin={10}
            dataKey='time'
            // type='number'
            domain={[from, to]}
            tickFormatter={time => formatXAxisLabel(time)}
            // scale='time'
            style={{ fontSize: '12px' }}
            padding={{ right: 10 }}
          />
          <YAxis domain={[-5, 5]} hide={true} />

          {/*2a2a2b*/}
          <Line key={currentUser.id} type='monotone' dataKey='fakeVal' stroke='#656565' strokeWidth={3} connectNulls={true} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

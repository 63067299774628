import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Menu from '../menu/Menu';

const isIos = Capacitor.getPlatform() === 'ios';

export default function RouteWrapper(): JSX.Element {
  return (
    <>
      <div
        className={classNames({
          'mb-safe': isIos,
          'mt-10': !isIos,
        })}
      >
        <Menu />
        <Outlet />
      </div>
    </>
  );
}

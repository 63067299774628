import { useCallback, useState } from 'react';
import { User, useUserObject } from '../context/UserContext';
import { getUser } from '../services/User';

export default function useUser(): {
  loadUser: (signal?: AbortSignal) => Promise<void>;
  user: User | null;
  setUser: (user: User | null) => void;
  loading: boolean;
} {
  const [loading, setLoading] = useState<boolean>(false);
  const { setUser, user } = useUserObject();

  const loadUser = useCallback(
    async (signal?: AbortSignal) => {
      setLoading(true);
      try {
        const user = await getUser(signal);

        setUser(user);

        return Promise.resolve();
      } catch (e) {
        setUser(null);
        return Promise.reject();
      } finally {
        setLoading(false);
      }
    },
    [setUser],
  );

  return {
    loadUser,
    user,
    setUser,
    loading,
  };
}

import React from 'react';
import ListItemWrapper from './ListItemWrapper';

interface Props {
  onClick?: () => void;
  prefix?: string | number;
  className?: string;
  alwaysFlex?: boolean; // this will not put the right column to the bottom, used for the groups
  children: React.ReactNode;
}

export default function ListItem({ prefix, children, onClick, className, alwaysFlex }: Props): JSX.Element {
  return (
    <ListItemWrapper onClick={onClick} className={className} alwaysFlex={alwaysFlex}>
      {prefix && (
        <div>
          <span className='text-sm text-gray-500'>#{prefix}</span> <span>{children}</span>
        </div>
      )}
      {!prefix && <>{children}</>}
    </ListItemWrapper>
  );
}

import stockOutlined from '@iconify/icons-ant-design/stock-outlined';
import { Icon } from '@iconify/react';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../components/header/Header';
import Map from '../components/map/Map';
import SpinnerFullPage from '../components/spinner/SpinnerFullPage';
import Toggle from '../components/toggle/Toggle';
import useUser from '../hooks/UseUser';
import { Coordinate, coordinateAvailPerMonth, getCoordinates } from '../services/Coordinates';
import { Device, getDevice } from '../services/Device';

export default function Maps(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true);
  const [coordinatesCached, setCoordinatesCached] = useState<Coordinate[]>([]);
  const [coordinates, setCoordinates] = useState<Coordinate[]>([]);
  const [device, setDevice] = useState<Device>();
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [daysAvail, setDaysAvail] = useState<string[]>([]);
  const [showLoraOnly, setShowLoraOnly] = useState<boolean>();
  const [showFixedGpsOnly, setShowFixedGpsOnly] = useState<boolean>();
  const [showInaccurateLora, setShowInaccurateLora] = useState<boolean>(false);
  const [showStoppedGps, setShowStopped] = useState<boolean>(false);
  const [asRoute, setAsRoute] = useState<boolean>(true);

  const { deviceId } = useParams<{ deviceId: string }>();
  const { user } = useUser();
  const { user: currentUser } = useUser();

  /**
   * Function that will check if the given date has some data
   * @param date
   */
  const isDayAvail = (date: Date) => {
    const givenDate = DateTime.fromJSDate(date);
    return daysAvail.includes(givenDate.toISODate());
  };

  /**
   * Load all the data
   */
  const loadData = useCallback(
    async (signal: AbortSignal) => {
      if (!deviceId || !startDate) return;

      const _coordinates = await getCoordinates(parseInt(deviceId), DateTime.fromJSDate(startDate).toISODate(), signal);
      setCoordinatesCached(_coordinates);
      getAvailDaysPerMonth(startDate);

      const _device = await getDevice(parseInt(deviceId), signal);
      setDevice(_device);
    },
    [deviceId, currentUser, startDate], //eslint-disable-line
  );

  /**
   * Get the dates that has some data
   */
  const getAvailDaysPerMonth = useCallback(
    (date: Date) => {
      if (!deviceId) return;
      coordinateAvailPerMonth(parseInt(deviceId), DateTime.fromJSDate(date).year, DateTime.fromJSDate(date).month).then(res =>
        setDaysAvail(res),
      );
    },
    [deviceId],
  );

  const filterInaccurateLora = (coord: Coordinate) => {
    if (coord.isLora && coord.radius > 0 && coord.radius < 500) {
      return true;
    } else if (!coord.isLora) {
      return true;
    }
  };

  /**
   * First load
   */
  useEffect(() => {
    const abortController = new AbortController();

    loadData(abortController.signal)
      .catch(e => {
        if (!abortController.signal.aborted) {
          console.error(e);
          toast.error('Cannot load the data');
        }
      })
      .finally(() => setLoading(false));

    return () => abortController.abort();
  }, [deviceId, currentUser, startDate]); //eslint-disable-line

  useEffect(() => {
    setCoordinates(coordinatesCached.filter(filterInaccurateLora));
  }, [coordinatesCached]);

  /**
   * Show fixed GPS only
   */
  useEffect(() => {
    if (showFixedGpsOnly) {
      setShowLoraOnly(false);
      setShowInaccurateLora(false);
      setCoordinates(() => coordinatesCached.filter(coordinate => !coordinate.isLora));
      if (showStoppedGps) {
        setCoordinates(coordinatesCached.filter(coordinate => !coordinate.isLora && coordinate.isStopped));
      }
    }
  }, [coordinatesCached, showFixedGpsOnly, showStoppedGps]);

  /**
   * Show loray only
   */
  useEffect(() => {
    if (showLoraOnly) {
      setShowFixedGpsOnly(false);
      setShowStopped(false);

      setCoordinates(() => coordinatesCached.filter(coordinate => coordinate.isLora).filter(filterInaccurateLora));
      if (showInaccurateLora) {
        setCoordinates(coordinatesCached.filter(coordinate => coordinate.isLora));
      }
    }
  }, [coordinatesCached, showInaccurateLora, showLoraOnly]);

  /**
   * Default
   */
  useEffect(() => {
    if (!showLoraOnly && !showFixedGpsOnly) {
      setCoordinates(coordinatesCached.filter(filterInaccurateLora));
    }
  }, [coordinatesCached, showFixedGpsOnly, showLoraOnly]);

  if (!loading && user) {
    return (
      <>
        <Header
          title={
            <>
              <Icon className='text-yellow-600' icon={stockOutlined} />
              Routes for {device?.name}
            </>
          }
        />

        <div className='w-full flex gap-x-2 justify-center items-center mb-5'>
          <div>
            <ReactDatePicker
              onMonthChange={e => getAvailDaysPerMonth(e)}
              className='z-20 p-2'
              selected={startDate}
              onChange={date => setStartDate(date)}
              filterDate={isDayAvail}
              placeholderText='Select a weekday'
            />
          </div>
        </div>
        <div className='w-full flex gap-x-2 justify-center items-center mb-5'>
          <div className='text-white text-sm flex flex-col sm:flex-row gap-1 items-center'>
            GPS Fixed
            <Toggle active={showFixedGpsOnly} onToggle={active => setShowFixedGpsOnly(active)} />
          </div>
          <div className='text-white text-sm flex flex-col sm:flex-row gap-1 items-center'>
            As Route
            <Toggle active={asRoute} onToggle={active => setAsRoute(active)} />
          </div>
          {showFixedGpsOnly && (
            <div className='text-white text-sm flex flex-col sm:flex-row gap-1 items-center'>
              Stopped
              <Toggle active={showStoppedGps} onToggle={active => setShowStopped(active)} />
            </div>
          )}
          <div className='border-l border-gray-600 pl-2 text-white text-sm flex flex-col sm:flex-row gap-1 items-center'>
            LoRa
            <Toggle active={showLoraOnly} onToggle={active => setShowLoraOnly(active)} />
          </div>

          {showLoraOnly && (
            <div className='text-white text-sm flex flex-col sm:flex-row gap-1 items-center'>
              Inaccurate Lora
              <Toggle
                active={showInaccurateLora}
                onToggle={active => {
                  setShowInaccurateLora(active);
                }}
              />
            </div>
          )}
        </div>

        <Map coordinates={coordinates} asRoute={asRoute} />
      </>
    );
  }

  return <SpinnerFullPage />;
}

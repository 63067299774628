import React, { createContext, useContext, useState } from 'react';

export interface UserDevice {
  id: number;
  name: string;
}

export interface User {
  id: number;
  name: string;
  email: string;
  devices: UserDevice[];
  timezone: string;
}

interface Props {
  user?: User | null;
  children: React.ReactNode;
}

export type UserContextType = {
  user: User | null;
  setUser: (user: User | null) => void;
};

const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => console.warn('no user provider'),
});

export function useUserObject(): UserContextType {
  return useContext(UserContext);
}

export function UserProvider({ children }: Props): JSX.Element {
  const [user, setUser] = useState<User | null>(null);

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
}

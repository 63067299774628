import { Icon, IconifyIcon } from '@iconify/react';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { Size, Type } from '../../types';
import Spinner from '../spinner/Spinner';

type ButtonType = Type | 'dangerOutline';

interface Props {
  size?: Size;
  type?: ButtonType;
  loading?: boolean;
  disabled?: boolean;
  active?: boolean;
  responsive?: boolean;
  className?: string;
  onClick?: () => void;
  htmlType?: 'submit' | 'reset' | 'button' | undefined;
  to?: To;
  tabIndex?: number;
  fullWidth?: boolean;
  icon?: IconifyIcon;
  iconShowOn?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  spinnerInverse?: boolean;
  loadingPosition?: 'left' | 'right';
  children: React.ReactNode;
  htmlForm?: string;
}

export default function Button({
  children,
  type = 'default',
  size = 'medium',
  htmlType = 'button',
  loading,
  disabled,
  active,
  responsive = true,
  onClick,
  className,
  to,
  tabIndex,
  icon,
  iconShowOn = 'xs',
  fullWidth,
  spinnerInverse,
  loadingPosition = 'right',
  htmlForm,
}: Props): JSX.Element {
  const inverseSpinnerColor = spinnerInverse === undefined ? type === 'default' : false;
  const isDisabled = !!loading || !!disabled;

  const navigate = useNavigate();

  /**
   * render the icon via the Icon comp
   */
  const renderedIcon = useMemo(() => {
    if (!icon || size === 'small') return;
    return (
      <Icon
        icon={icon}
        className={classNames('hidden mr-1', {
          'xs:block': iconShowOn === 'xs',
          'sm:block': iconShowOn === 'sm',
          'md:block': iconShowOn === 'md',
          'lg:block': iconShowOn === 'lg',
          'xl:block': iconShowOn === 'xl',
          '2xl:block': iconShowOn === '2xl',
        })}
      />
    );
  }, [icon, iconShowOn, size]);

  /**
   * Event when the user click on the button
   */
  const click = () => {
    if (to) {
      navigate(to);
    } else {
      onClick?.();
    }
  };

  return (
    <button
      form={htmlForm}
      type={htmlForm ? 'submit' : htmlType}
      className={classNames(
        'bg-clip-padding', // https://stackoverflow.com/questions/71123245/firefox-scaling-issues-causes-pixelated-blurry-borders
        'inline-flex',
        'justify-center',
        'items-center',
        'border border-transparent',
        'font-medium',
        {
          'rounded-md': type !== 'link',
        },
        {
          'sm:w-auto': !fullWidth,
          'w-full': responsive || fullWidth,
          'shadow-sm': type !== 'link',
        },
        // Disabled
        {
          'cursor-default': isDisabled,
          'cursor-not-allowed': isDisabled,
          'disabled:opacity-50': isDisabled && !active,
        },
        // Small font size
        {
          'text-xs': size === 'small',
          'px-2': size === 'small',
          'py-1': size === 'small',
        },
        // Medium font size
        {
          'text-sm': size === 'medium',
          'px-4': size === 'medium' && !icon,
          'pl-2 pr-3': size === 'medium' && icon,
          'py-2': size === 'medium',
        },
        // Large font size
        {
          'text-lg': size === 'large',
          'px-6': size === 'large' && !icon,
          'pl-3 pr-4': size === 'large' && icon,
          'py-3': size === 'large',
        },
        // Primary color classes
        {
          'text-white bg-primary hover:bg-opacity-80': type === 'primary',
          'bg-opacity-80 !border-gray-500': type === 'primary' && active,
        },
        // Default color classes
        {
          'text-gray-600 border-gray-300 bg-white hover:bg-gray-50': type === 'default',
          'bg-gray-50 !border-gray-400': type === 'default' && active,
        },
        // Danger color classes
        {
          'text-white bg-danger-600 hover:bg-danger-700': type === 'danger',
          'bg-danger-700': type === 'danger' && active,
        },
        // Danger-outline color classes
        {
          'text-danger-600 border-danger-300 bg-white hover:bg-danger-50': type === 'dangerOutline',
          'bg-danger-50 !border-danger-400': type === 'dangerOutline' && active,
        },
        // Default color classes
        {
          'text-gray-600 hover:text-primary border-0': type === 'link',
          'text-gray-800': type === 'link' && active,
        },
        className,
      )}
      onClick={click}
      disabled={isDisabled}
      tabIndex={tabIndex}
    >
      {renderedIcon}
      {loading && loadingPosition === 'left' && <Spinner className='ml-2' inverseColor={inverseSpinnerColor} size={size} />}
      {children}
      {loading && loadingPosition === 'right' && <Spinner className='ml-2' inverseColor={inverseSpinnerColor} size={size} />}
    </button>
  );
}

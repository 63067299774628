import warningOutlined from '@iconify/icons-ant-design/warning-outlined';
import { Icon, IconifyIcon } from '@iconify/react';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Modal, { ModalWidth } from '../index';

interface ConfirmDialogProps {
  type?: 'notice' | 'warning' | 'danger';
  showSideIcon?: boolean | IconifyIcon;
  visible?: boolean;
  loading?: boolean;
  showSubmitButton?: boolean;
  title?: string;
  text?: string | React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  onCancel?: () => void;
  onConfirm?: () => void | Promise<void | any>; //eslint-disable-line
  isSkippable?: boolean; // mark a confirm as skipable, this let the user skip the confirm next time
  id?: string; // ID is mostly used for the local storage key in combination with the isSkipable bool
}

/**
 * ConfirmDialog is a dialog that set some default vars for the Dialog component so it act as an confirm dialog
 */
export default function ConfirmDialog({
  type,
  showSideIcon = false,
  visible,
  showSubmitButton,
  text,
  title,
  onCancel,
  onConfirm,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  isSkippable,
  id,
}: ConfirmDialogProps): JSX.Element {
  const [danger, setDanger] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();
  const [markAsSkipped, setMarkAsSkipped] = useState<boolean>();

  const sideIcon: IconifyIcon = typeof showSideIcon !== 'boolean' ? showSideIcon : warningOutlined;
  const sideIconElement =
    type && showSideIcon ? (
      <div
        className={classNames(
          'mx-auto',
          'sm:mr-2',
          'shrink-0',
          'flex',
          'items-center',
          'justify-center',
          'h-12',
          'w-12',
          'rounded-full',
          'sm:mx-0',
          'sm:h-10',
          'sm:w-10',
          {
            'bg-danger-100': type === 'danger',
            'bg-notice-100': type === 'notice',
            'bg-warning-100': type === 'warning',
          },
        )}
      >
        <Icon icon={sideIcon} width={20} height={20} />
      </div>
    ) : (
      <></>
    );

  /**
   * Render the checkbox and helpText to skip the confirm dialog next time
   */
  const skippableInput = useMemo(() => {
    if (isSkippable && !id) {
      console.error('ID is required when using the skippable={true} function. e.g. Confirm({id="your-id"});');
      return null;
    }
    if (!isSkippable || !id) return null;

    return (
      <div className='flex gap-x-1 items-center'>
        <input
          type='checkbox'
          id='skip-confirm-next-time'
          autoFocus={false}
          onChange={({ currentTarget }) => setMarkAsSkipped(currentTarget.checked)}
        />
        <label htmlFor='skip-confirm-next-time' className='text-xs text-gray-500 cursor-pointer select-none'>
          Do not show this confirmation again
        </label>
      </div>
    );
  }, [id, isSkippable]);

  /**
   * We can either receive a void function or a promise.
   * In case of a promise we also enable the loading icon
   *
   * We also check if the user has marked this confirm to be skipped next time.
   */
  const confirm = () => {
    if (markAsSkipped && id) {
      const safeId = id.toLowerCase().replaceAll(' ', '-');
      const localStorageId = `confirm-skipable-${safeId}`;
      localStorage.setItem(localStorageId, '1');
    }

    if (onConfirm) {
      const isPromise = onConfirm();
      if (isPromise?.then) {
        setLoading(true);
      }
    }
  };

  /**
   * set the danger type if needed
   */
  useEffect(() => {
    setDanger(type === 'danger');
  }, [type]);

  return (
    <Modal
      remainSizeOnSmallerScreens={true}
      danger={danger}
      sideNode={sideIconElement}
      visible={visible}
      showCloseIcon={false}
      title={title}
      onCancel={onCancel}
      onOk={confirm}
      okText={confirmText}
      cancelText={cancelText}
      loading={loading}
      showSubmitButton={showSubmitButton}
      width={ModalWidth.md}
      footerSideNode={skippableInput}
    >
      {text}
    </Modal>
  );
}

type ConfirmProps = Pick<
  ConfirmDialogProps,
  | 'text'
  | 'title'
  | 'confirmText'
  | 'cancelText'
  | 'onCancel'
  | 'onConfirm'
  | 'type'
  | 'showSideIcon'
  | 'showSubmitButton'
  | 'id'
  | 'isSkippable'
>;

/**
 * The Confirm() is an API method that will create the <ConfirmDialog> with createRoot()
 *
 * Example:
 *
 * Confirm({
 *  title: 'title',
 *  text: 'test',
 *  onConfirm: () => {
 *    alert('confirm');
 *  },
 *  onCancel: () => {
 *    alert('cancel');
 *  },
 * });
 */
export function Confirm({ onCancel, onConfirm, ...props }: ConfirmProps): void {
  const div = document.createElement('div');
  const root = createRoot(div!); //eslint-disable-line
  document.body.appendChild(div);

  /**
   * Cancel event will run the user function but also destroy the element
   */
  const cancel = () => {
    if (onCancel) {
      onCancel();
    }

    destroy();
  };

  /**
   * Confirm event will run the user function but also destroy the element
   *
   * This can be either a promise or void function. In case of a promise, call the destroy method in the finally method
   */
  const confirm = () => {
    if (onConfirm) {
      const isPromise = onConfirm();
      if (isPromise?.then) {
        return isPromise?.finally(() => destroy());
      } else {
        destroy();
      }
    } else {
      destroy();
    }
  };

  /**
   * This method will destroy and cleanup the created div and the components that are attached to
   */
  const destroy = () => {
    root.unmount();
    if (div.parentNode) {
      div.parentNode.removeChild(div);
    }
  };

  /**
   * Check if the user has marked this Confirm as skipped,
   * If so, we can fire the confirm() method directly instead of showing t he confirmDialog
   */
  const safeId = props.id?.toLowerCase().replaceAll(' ', '-');
  const localStorageId = `confirm-skipable-${safeId}`;
  if (localStorage.getItem(localStorageId) === '1') {
    confirm();
    return;
  }

  root.render(
    <BrowserRouter>
      <ConfirmDialog {...props} visible={true} onCancel={cancel} onConfirm={confirm} />
    </BrowserRouter>,
  );
}

import eyeFilled from '@iconify/icons-ant-design/eye-filled';
import eyeInvisibleFilled from '@iconify/icons-ant-design/eye-invisible-filled';
import { Icon } from '@iconify/react';
import classNames from 'classnames';
import React, { ForwardedRef, useState } from 'react';
import { inputClassesLight } from '../../helpers/CssClasses';

interface Props extends React.SelectHTMLAttributes<HTMLInputElement> {
  className?: string;
  newPassword: boolean;
}

/**
 * Password element with the option to show or hide the password
 *
 * We can use this <Password/> component inside the Forms-Hooks as it is 100% compatible with the hooks
 */
const Password = React.forwardRef(function Password(
  { newPassword, className, ...rest }: Props,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <div className='relative'>
      <input
        autoComplete={newPassword ? 'new-password' : 'current-password'}
        {...rest}
        ref={ref}
        type={showPassword ? 'text' : 'password'}
        className={classNames('w-full pr-8', className, inputClassesLight)}
      />
      <button type='button' onClick={() => setShowPassword(prevState => !prevState)} tabIndex={-1}>
        <Icon icon={showPassword ? eyeInvisibleFilled : eyeFilled} className='absolute right-3 top-2 text-gray-500' />
      </button>
    </div>
  );
});

export default Password;
